import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Section, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Ласкаво просимо до Creatix Agency
			</title>
			<meta name={"description"} content={"В агентстві Creatix ми прагнемо зрозуміти ваше унікальне бачення та перетворити його на переконливі маркетингові стратегії, які говорять багато про що."} />
			<meta property={"og:title"} content={"Ласкаво просимо до Creatix Agency"} />
			<meta property={"og:description"} content={"В агентстві Creatix ми прагнемо зрозуміти ваше унікальне бачення та перетворити його на переконливі маркетингові стратегії, які говорять багато про що."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66583b441d712a00233449af/images/1-1%20%281%29.jpg?v=2024-05-30T11:03:45.343Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66583b441d712a00233449af/images/1-1%20%281%29.jpg?v=2024-05-30T11:03:45.343Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66583b441d712a00233449af/images/1-1%20%281%29.jpg?v=2024-05-30T11:03:45.343Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66583b441d712a00233449af/images/1-1%20%281%29.jpg?v=2024-05-30T11:03:45.343Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66583b441d712a00233449af/images/1-1%20%281%29.jpg?v=2024-05-30T11:03:45.343Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66583b441d712a00233449af/images/1-1%20%281%29.jpg?v=2024-05-30T11:03:45.343Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66583b441d712a00233449af/images/1-1%20%281%29.jpg?v=2024-05-30T11:03:45.343Z"} />
		</Helmet>
		<Components.Header123>
			<Override slot="link2" />
			<Override slot="text" />
		</Components.Header123>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 32px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://uploads.quarkly.io/66583b441d712a00233449af/images/1-1%20%281%29.jpg?v=2024-05-30T11:03:45.343Z"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="auto"
					max-width="100%"
					sm-min-height="100vw"
					srcSet="https://smartuploads.quarkly.io/66583b441d712a00233449af/images/1-1%20%281%29.jpg?v=2024-05-30T11%3A03%3A45.343Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66583b441d712a00233449af/images/1-1%20%281%29.jpg?v=2024-05-30T11%3A03%3A45.343Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66583b441d712a00233449af/images/1-1%20%281%29.jpg?v=2024-05-30T11%3A03%3A45.343Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66583b441d712a00233449af/images/1-1%20%281%29.jpg?v=2024-05-30T11%3A03%3A45.343Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66583b441d712a00233449af/images/1-1%20%281%29.jpg?v=2024-05-30T11%3A03%3A45.343Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66583b441d712a00233449af/images/1-1%20%281%29.jpg?v=2024-05-30T11%3A03%3A45.343Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66583b441d712a00233449af/images/1-1%20%281%29.jpg?v=2024-05-30T11%3A03%3A45.343Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 16px"
				justify-content="center"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Ласкаво просимо в майбутнє вашого бренду!
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					В агентстві Creatix ми прагнемо зрозуміти ваше унікальне бачення та перетворити його на переконливі маркетингові стратегії, які говорять багато про що. Дозвольте нам орієнтуватися у складному світі ринкових тенденцій і поведінки споживачів, щоб підвищити охоплення та вплив вашого бренду.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				justify-content="center"
				lg-order="1"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Про нас
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					Creatix Agency процвітає завдяки креативності та інноваціям. Маючи десятирічний досвід керування ринковими кампаніями для різноманітних галузей, ми маємо інструменти та досвід, щоб зробити ваш бренд не просто помітним, а запам’ятованим. Наш підхід є цілісним, поєднує передові технології з креативним оповіданням, щоб досягти важливих результатів.
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-green"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					Контакти
				</Link>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 32px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://uploads.quarkly.io/66583b441d712a00233449af/images/3-2.jpg?v=2024-05-30T11:03:45.391Z"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="auto"
					max-width="100%"
					sm-min-height="100vw"
					srcSet="https://smartuploads.quarkly.io/66583b441d712a00233449af/images/3-2.jpg?v=2024-05-30T11%3A03%3A45.391Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66583b441d712a00233449af/images/3-2.jpg?v=2024-05-30T11%3A03%3A45.391Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66583b441d712a00233449af/images/3-2.jpg?v=2024-05-30T11%3A03%3A45.391Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66583b441d712a00233449af/images/3-2.jpg?v=2024-05-30T11%3A03%3A45.391Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66583b441d712a00233449af/images/3-2.jpg?v=2024-05-30T11%3A03%3A45.391Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66583b441d712a00233449af/images/3-2.jpg?v=2024-05-30T11%3A03%3A45.391Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66583b441d712a00233449af/images/3-2.jpg?v=2024-05-30T11%3A03%3A45.391Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 32px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://uploads.quarkly.io/66583b441d712a00233449af/images/3-1.jpg?v=2024-05-30T11:03:45.349Z"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					max-width="100%"
					sm-min-height="100vw"
					object-position="30%"
					srcSet="https://smartuploads.quarkly.io/66583b441d712a00233449af/images/3-1.jpg?v=2024-05-30T11%3A03%3A45.349Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66583b441d712a00233449af/images/3-1.jpg?v=2024-05-30T11%3A03%3A45.349Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66583b441d712a00233449af/images/3-1.jpg?v=2024-05-30T11%3A03%3A45.349Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66583b441d712a00233449af/images/3-1.jpg?v=2024-05-30T11%3A03%3A45.349Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66583b441d712a00233449af/images/3-1.jpg?v=2024-05-30T11%3A03%3A45.349Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66583b441d712a00233449af/images/3-1.jpg?v=2024-05-30T11%3A03%3A45.349Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66583b441d712a00233449af/images/3-1.jpg?v=2024-05-30T11%3A03%3A45.349Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 16px"
				justify-content="center"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Наші переваги
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					Компетентність і досвід: Використовуйте наші глибокі знання галузі, щоб розробляти стратегії, які ламають шаблони.
					<br />
					<br />
					Індивідуальні стратегії: Ми віримо в рішення, які відображають ваш унікальний бренд, гарантуючи, що кожна кампанія є такою ж індивідуальною, як і ви.
					<br />
					<br />
					Статистика на основі даних: Будьте попереду разом з нами, оскільки ми використовуємо найновішу ринкову аналітику, щоб скеровувати шлях вашого бренду.
					<br />
					<br />
					Клієнтоорієнтований підхід:  Ваше бачення є нашим пріоритетом. Кожен крок, який ми робимо, адаптований відповідно до ваших потреб, гарантуючи, що голос вашого бренду завжди буде почутий.
				</Text>
				<Link
					href="/services"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-green"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					Послуги
				</Link>
			</Box>
		</Section>
		<Components.Footernew>
			<Override slot="link2" />
			<Override slot="text2" />
			<Override slot="link4" />
		</Components.Footernew>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65dde651926d910020ed4648"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});